// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Breadcrumbs from '../components/breadcrumbs'
import '../components/breadcrumbs/style.less'

import Link from '../components/link'
import '../components/link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Learn',
  slug: '/learn',
  abstract: 'Learn',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Learn', slug: '/learn' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    launch: file(relativePath: { eq: "launch.jpg" }) {
      ...max900
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper pageSchema={pageSchema} {...props}>
    <div className="container">
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
      >
        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
          <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
          <h1>Learn</h1>
          <p>
            The Aura Network is working on a unique learning program in
            alternative economics. Our team members specialize in a wide range
            of topics, including development economics, economic history,
            applied and theoretical econometrics, economics of inequality and
            gender, environmental economics, industrial organization,
            international finance, international trade, labour economics,
            macroeconomics, applied and theoretical micro, political economy,
            and public economics, and we are working on a unique offering for
            learners in Auroville and beyond.
          </p>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <aside
            className="responsive-aside"
            style={{
              borderTop: '2px solid var(--green2)',
              padding: '1.1rem',
              background: 'var(--gray2)',
              marginLeft: '1.1rem',
              borderBottomLeftRadius: '0.55rem',
              borderBottomRightRadius: '0.55rem',
              overflow: 'hidden',
            }}
          >
            <h3>Meet us</h3>
            <p>
              You can also write to us at{' '}
              <Link to="mailto:admin@auranetwork.app">
                admin@auranetwork.app
              </Link>{' '}
              and request to meet us in person. The Aura Project is a dynamic
              project and we keep on evolving and adapting based on inputs we
              recieve from the community.
            </p>
          </aside>
          <aside
            className="responsive-aside"
            style={{
              borderTop: '2px solid var(--green2)',
              padding: '1.1rem',
              background: 'var(--gray2)',
              marginLeft: '1.1rem',
              marginTop: '1.1rem',
              borderBottomLeftRadius: '0.55rem',
              borderBottomRightRadius: '0.55rem',
              overflow: 'hidden',
            }}
          >
            <h3>Other useful links</h3>
            <p>
              <Link className="alt" to="/tutorial/introduction">
                Tutorial&nbsp;<span className="hand">☞</span>
              </Link>
              <br />
              <Link className="alt" to="/report-a-bug">
                Report a bug&nbsp;<span className="hand">☞</span>
              </Link>
              <br />
              <Link className="alt" to="/community-feedback">
                Community feedback&nbsp;<span className="hand">☞</span>
              </Link>
              <br />
              <Link className="alt" to="/give-feedback">
                Give us feedback&nbsp;<span className="hand">☞</span>
              </Link>
            </p>
          </aside>
          <aside
            className="responsive-aside"
            style={{
              borderTop: '2px solid var(--green2)',
              padding: '1.1rem',
              background: 'var(--gray2)',
              marginLeft: '1.1rem',
              marginTop: '1.1rem',
              borderBottomLeftRadius: '0.55rem',
              borderBottomRightRadius: '0.55rem',
              overflow: 'hidden',
            }}
          >
            <h3>Learn with us</h3>
            <p>
              <Link className="alt" to="/apprentice-or-intern">
                Apprentice or intern&nbsp;<span className="hand">☞</span>
              </Link>
              <br />
              <Link className="alt" to="/learn">
                Learn&nbsp;<span className="hand">☞</span>
              </Link>
              <br />
              <Link className="alt" to="https://book.auranetwork.app/en">
                Web book&nbsp;<span className="hand">☞</span>
              </Link>
            </p>
          </aside>
          <aside
            className="responsive-aside"
            style={{
              borderTop: '2px solid var(--green2)',
              padding: '1.1rem',
              background: 'var(--gray2)',
              marginLeft: '1.1rem',
              marginTop: '1.1rem',
              borderBottomLeftRadius: '0.55rem',
              borderBottomRightRadius: '0.55rem',
              overflow: 'hidden',
            }}
          >
            <h3>Support us</h3>
            <p>
              Please consider donating so we can give shape to the Aura Project
              for Auroville and beyond.
            </p>
            <p>
              <Link className="alt" to="/donate">
                Donate page&nbsp;<span className="hand">☞</span>
              </Link>
            </p>
          </aside>
        </Col>
      </Row>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
